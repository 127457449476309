import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { kebabCase } from 'lodash'

const FooterServicesMenu = () => {
  const data = useStaticQuery(graphql`
    query FooterServicesMenu {
      allWpPage(filter: { slug: { eq: "services" } }) {
        nodes {
          servicesPage {
            service {
              serviceTitle
            }
          }
        }
      }
    }
  `)

  const serviceNavMenuItems = data.allWpPage.nodes[0].servicesPage.service
  if (serviceNavMenuItems.length > 0) {
    return (
      <div className="[ hidden md:block md:col-span-3 ]">
        <ul className="[ mt-0 ]">
          {serviceNavMenuItems.map((menuItem, i) => {
            const serviceId = kebabCase(menuItem.serviceTitle)

            return (
              <li className={`${i === 0 ? '[ mt-0 ]' : '[ mb-2 ]'}`} key={i}>
                <Link
                  to={`/services#${serviceId}/`}
                  className="[ no-underline hover:underline ]"
                >
                  {menuItem.serviceTitle}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return false
}

export default FooterServicesMenu
